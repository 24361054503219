<template>
  <div class="d-flex justify-content-center ">
    <div>
      <b-alert class="w-100 mt-1" variant="primary" fade show>
        <!-- <h4 class="alert-heading">Danger</h4> -->
        <!-- <div class="alert-body text-center" @click="() => this.$router.push({ name: 'historyMeet' })" style="cursor: pointer">
          <feather-icon icon="BellIcon" class="mr-50" />
          <span>รายการนัดหมาย {{ this.meetTingCount }}</span>
        </div> -->
        <div class="alert-body text-center">
          <feather-icon icon="BellIcon" class="mr-50" />
          <span>รายการนัดหมาย {{ this.meetTingCount }}</span>
        </div>
      </b-alert>
      <div class="d-flex justify-content-center ">
        <div class="card col-12 col-md-6">
          <div class="card-body">
            <validation-observer ref="submitForm">
              <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="สถานที่ติดต่อ (บ้านเลขที่ หรือ ฝ่าย)" label-cols-md="4">
                      <validation-provider #default="{ errors }" name="สถานที่ติดต่อ (บ้านเลขที่ หรือ ฝ่าย)" rules="required">
                        <b-form-input v-model="formMeeting.place" :state="errors.length > 0 ? false : null" placeholder="สถานที่ติดต่อ (บ้านเลขที่ หรือ ฝ่าย)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="ชื่อสกุล Visitor" label-cols-md="4">
                      <validation-provider #default="{ errors }" name="ชื่อสกุล Visitor" rules="required">
                        <b-form-input v-model="formMeeting.nameVisit" :state="errors.length > 0 ? false : null" placeholder="ชื่อสกุล Visitor" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="ทะเบียนรถ Visitor" label-cols-md="4">
                      <validation-provider #default="{ errors }" name="ทะเบียนรถ Visitor" rules="required">
                        <b-form-input v-model="formMeeting.vehicleVisit" :state="errors.length > 0 ? false : null" placeholder="ทะเบียนรถ Visitor" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="ชื่อสกุล Host (เจ้าของบ้าน หรือ พนักงานในฝ่าย)" label-cols-md="4">
                      <validation-provider #default="{ errors }" name="ชื่อสกุล Host (เจ้าของบ้าน หรือ พนักงานในฝ่าย)" rules="required">
                        <b-form-input v-model="formMeeting.nameMeet" :state="errors.length > 0 ? false : null" placeholder="ชื่อสกุล Host (เจ้าของบ้าน หรือ พนักงานในฝ่าย)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="วันที่พบ (ว/ด/ป)" label-cols-md="4">
                      <validation-provider #default="{ errors }" name="วันที่พบ" rules="required">
                        <b-form-datepicker
                          v-model="formMeeting.date"
                          @context="updateYear"
                          :state="errors.length > 0 ? false : null"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          placeholder="วันที่พบ"
                          label-today-button="วันนี้"
                          label-reset-button="รีเซ็ต"
                          today-button
                          reset-button
                        >
                        </b-form-datepicker>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="เวลาพบ " label-cols-md="4">
                      <validation-provider #default="{ errors }" name="เวลาพบ" rules="required">
                        <b-form-timepicker v-model="formMeeting.time" :state="errors.length > 0 ? false : null" :locale="locale" placeholder="เวลาพบ" label-now-button="เวลานี้" label-reset-button="รีเซ็ต" now-button no-close-button reset-button />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col offset-md="4">
                    <b-button @click.prevent="submitForm()" type="submit" variant="primary" class="mr-1"> บันทึก </b-button>
                    <b-button @click="resetForm()" type="reset" variant="outline-secondary"> รีเซ็ต </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
  import { required } from '@validations';
  import axios from 'axios';
  import API from '@/connect/config';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        // ? VeeValidate
        required,
        //? locale
        locale: 'th',
        // ? รายการนัดหมาย
        meetTingCount: '',
        // ? สร้างนัดหมาย
        formMeeting: {
          place: '', // สถานที่ติดต่อ ( บ้านเลขที่ )
          nameVisit: '', // ชื่อ-สกุล Visitor
          vehicleVisit: '', // เลขทะเบียนรถ Visitor
          nameMeet: '', // ชื่อ-สกุล ผู้มาขอเข้าพบ
          date: '',
          time: '',
        },
        // ? loading
        playLoad: false,

        BBBB: dayjs().locale('th').format('BBBB'),
        YYYY: dayjs().locale('th').format('YYYY'),
      };
    },
    async created() {
      localize(this.locale);
      this.getTotal();
    },
    methods: {
      updateYear(context) {
        // console.log(context);
        //console.log(dayjs(context.activeDate).year(this.BBBB));

        let currentDate = dayjs(this.formMeeting.date);
        let updatedDate = currentDate.year(this.BBBB);

        // Update the date value with the new year
        this.formMeeting.date = updatedDate.format('YYYY-MM-DD');
      },
      getTotal() {
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        let uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
        axios
          .get(`${API}meeting/${userId}?uId=${uId}`)
          .then((res) => {
            let i = 0;
            res.data.message.result.map((el, index) => {
              if (uId == el.uId) {
                i++;
                this.meetTingCount = i;
              }
            });

            // console.clear();
          })
          .catch((err) => {
            console.error('LIFF initialization failed', err);
          });
      },
      async submitForm() {
        this.$refs.submitForm.validate().then((success) => {
          if (success) {
            this.playLoad = true;
            const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
            const uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
            const { nameMeet, nameVisit, place, date, time, vehicleVisit } = this.formMeeting;

            let currentDate = dayjs(date);
            let updatedDate = currentDate.year(this.YYYY);
            console.log(updatedDate.format('YYYY-MM-DD'));
            // date = updatedDate.format('YYYY-MM-DD');
            console.log(currentDate);
            const dateAndtime = new Date(`${updatedDate.format('YYYY-MM-DD')}T${time}`).toISOString();
            const datameeting = {
              userId: userId,
              uId: uId,
              nameMeet: nameMeet, // ชื่อ-สกุล ผู้มาขอเข้าพบ
              name: nameVisit, // ชื่อ-สกุล Visitor
              meetUpLocal: place, // สถานที่ติดต่อ ( บ้านเลขที่ )
              daysToCome: dateAndtime, // วันที่พบ
              licensePlate: vehicleVisit, // เลขทะเบียนรถ Visitor
              status: 'นัด',
            };
            console.log(datameeting);

            axios
              .post(`${API}meeting`, datameeting)
              .then((res) => {
                this.$store.commit('setToastMessage', {
                  message: res.data.message,
                  title: 'สร้างนัดหมาย',
                  variant: 'primary',
                });
                this.resetForm();
                this.getTotal();
                this.playLoad = false;
              })
              .catch((err) => {
                console.error('LIFF initialization failed', err);
              });
          } else {
            // switch the locale.
            localize(this.locale);
            // re-validate to re-generate the messages.
            this.$refs.submitForm.validate();
            this.playLoad = false;
          }
        });
      },
      resetForm() {
        this.formMeeting = {
          place: '', // สถานที่ติดต่อ ( บ้านเลขที่ )
          nameVisit: '', // ชื่อ-สกุล Visitor
          vehicleVisit: '', // เลขทะเบียนรถ Visitor
          nameMeet: '', // ชื่อ-สกุล ผู้มาขอเข้าพบ
          date: '',
          time: '',
        };

        this.$nextTick(() => {
          this.$refs.submitForm.reset();
        });
      },
    },
  };
</script>

<style></style>
